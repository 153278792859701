<template>
  <v-form @submit.prevent="uploadFile">
    <p style="color: gray">
      Notice: The file type must be MSword, MSexcel, pdf or image: jpeg/png.
    </p>

    <div v-for="uploadSection in this.uploadSection" :key="uploadSection.id">
      <v-row
        v-for="(row, index) in uploadSection.rows"
        :key="index"
        :class="row.class"
      >
        <v-col
          class="overflow-hidden"
          v-for="column in row.columns"
          :key="column.id"
          :cols="column.cols"
        >
          <div v-for="inputFile in column.inputFiles" :key="inputFile.id">
            <v-file-input
              multiple
              :accept="inputFile.accept"
              :label="inputFile.label"
              :rules="inputFile.rules"
              v-model="inputFile.files"
            />
          </div>

          <div
            v-for="selectCategory in column.selectCategories"
            :key="selectCategory.id"
          >
            <v-select
              :items="selectCategory.items"
              :label="selectCategory.label"
              :rules="selectCategory.rules"
              v-model="selectCategory.category"
            />
          </div>

          <div v-for="description in column.descriptions" :key="description.id">
            <v-textarea
              dark
              :label="description.label"
              :rows="description.rows"
              v-model="description.description"
            />
          </div>

          <div>
            <v-icon
              v-for="icon in getUploadRowsLen === index + 1 // this is to define that the add icon should be always at the last row
                ? column.iconAddDelete
                : column.iconDelete"
              :key="icon.id"
              :title="icon.title"
              :style="icon.style"
              :class="icon.class"
              @click="
                icon.click === 'add-row'
                  ? addUploadRow()
                  : deleteUploadRow(index)
              "
            >
              {{ icon.iconName }}
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row>
        <v-col>
          <v-btn
            v-show="!isUploadComplete"
            type="submit"
            transition="fade-transition"
            :disabled="
              this.uploadSection[0].rows[0].columns[0].inputFiles[0].files ===
              null
                ? true
                : false
            "
          >
            <span v-if="!isUploading">upload ({{ getUploadRowsLen }})</span>
            <span v-if="isUploading && !isUploadComplete">
              uploading files
              <v-progress-circular indeterminate color="primary" />
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-alert
            v-show="isUploadComplete"
            transition="fade-transition"
            type="success"
          >
            Uploaded successfully except for other file types that are not
            mentioned above.
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import API from '@/plugins/API';

export default {
  name: 'UploadFiles',

  props: ['dealData'],

  data() {
    return {
      isUploading: false,
      isUploadComplete: false,
      categoryData: null,
      selectedCategoryId: null,
      uploadMsg: '',

      uploadSection: [
        {
          rows: [
            {
              id: 1,
              class: 'd-flex flex-wrap align-center',

              columns: [
                {
                  id: 1,
                  cols: '2',

                  inputFiles: [
                    {
                      id: 1,
                      files: null,
                      rules: [
                        (v) => !!v || 'This is required',
                        (v) => (v && v.length > 0) || 'This is required',
                      ],
                      accept:
                        '.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png',
                      label: 'Upload files',
                    },
                  ],
                },

                {
                  id: 2,
                  cols: '2',

                  selectCategories: [
                    {
                      id: 1,
                      category: '',
                      label: 'Select Category',
                      items: [],
                      rules: [
                        (v) => !!v || 'This is required',
                        (v) => (v && v.length > 0) || 'This is required',
                      ],
                    },
                  ],
                },

                {
                  id: 3,
                  cols: '6',

                  descriptions: [
                    {
                      id: 1,
                      description: '',
                      label: 'File Description (optional)',
                      rows: '1',
                    },
                  ],
                },

                {
                  id: 4,
                  cols: '2',

                  iconAddDelete: [
                    {
                      id: 1,
                      title: 'add',
                      style: 'cursor: pointer',
                      iconName: 'mdi-plus-circle',
                      click: 'add-row',
                    },

                    {
                      id: 2,
                      class: 'ml-5',
                      title: 'delete',
                      style: 'cursor: pointer',
                      iconName: 'mdi-delete-circle',
                      click: 'delete-row',
                    },
                  ],

                  iconDelete: [
                    {
                      id: 1,
                      title: 'delete',
                      style: 'cursor: pointer',
                      iconName: 'mdi-delete-circle',
                      click: 'delete-row',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },

  computed: {
    getUploadRowsLen() {
      const len = this.uploadSection[0].rows.length;
      return len;
    },
  },

  methods: {
    addUploadRow() {
      const idNum = this.getUploadRowsLen + 1;

      // destructuring the items in category
      const [
        {
          rows: [
            {
              columns: [
                ,
                {
                  selectCategories: [{ items }],
                },
              ],
            },
          ],
        },
      ] = this.uploadSection;

      // creating a new row for upload field
      this.uploadSection[0].rows.push({
        id: idNum,
        class: 'd-flex flex-wrap align-center',

        columns: [
          {
            id: 1,
            cols: '2',

            inputFiles: [
              {
                id: 1,
                files: null,
                rules: [
                  (v) => !!v || 'This is required',
                  (v) => (v && v.length > 0) || 'This is required',
                ],
                accept: '.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png',
                label: 'Upload files',
              },
            ],
          },

          {
            id: 2,
            cols: '2',

            selectCategories: [
              {
                id: 1,
                category: '',
                label: 'Select Category',
                items: items,
                rules: [
                  (v) => !!v || 'This is required',
                  (v) => (v && v.length > 0) || 'This is required',
                ],
              },
            ],
          },

          {
            id: 3,
            cols: '6',

            descriptions: [
              {
                id: 1,
                description: '',
                label: 'File Description (optional)',
                rows: '1',
              },
            ],
          },

          {
            id: 4,
            cols: '2',

            iconAddDelete: [
              {
                id: 1,
                title: 'add',
                style: 'cursor: pointer',
                iconName: 'mdi-plus-circle',
                click: 'add-row',
              },

              {
                id: 2,
                class: 'ml-5',
                title: 'delete',
                style: 'cursor: pointer',
                iconName: 'mdi-delete-circle',
                click: 'delete-row',
              },
            ],

            iconDelete: [
              {
                id: 1,
                title: 'delete',
                style: 'cursor: pointer',
                iconName: 'mdi-delete-circle',
                click: 'delete-row',
              },
            ],
          },
        ],
      });
    },

    deleteUploadRow(index) {
      this.uploadSection[0].rows.splice(index, 1);
    },

    async getCategoryData() {
      // unpack values from the items in category
      const [
        {
          rows: [
            {
              columns: [
                ,
                {
                  selectCategories: [{ items }],
                },
              ],
            },
          ],
        },
      ] = this.uploadSection;

      // adding the items in the category from strapi backend
      await API()
        .get('/api/internal-admin/get_upload_file_category')
        .then((res) => {
          res.data.forEach((element) => {
            items.push(element.category_name);
          });
          this.categoryData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resetUploadFields() {
      this.selectedCategoryId = null;
      this.isUploading = false;
      this.isUploadComplete = false;

      this.uploadSection = [
        {
          rows: [
            {
              id: 1,
              class: 'd-flex flex-wrap align-center',

              columns: [
                {
                  id: 1,
                  cols: '2',

                  inputFiles: [
                    {
                      id: 1,
                      files: null,
                      rules: [
                        (v) => !!v || 'This is required',
                        (v) => (v && v.length > 0) || 'This is required',
                      ],
                      accept:
                        '.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png',
                      label: 'Upload files',
                    },
                  ],
                },

                {
                  id: 2,
                  cols: '2',

                  selectCategories: [
                    {
                      id: 1,
                      category: '',
                      label: 'Select Category',
                      items: [],
                      rules: [
                        (v) => !!v || 'This is required',
                        (v) => (v && v.length > 0) || 'This is required',
                      ],
                    },
                  ],
                },

                {
                  id: 3,
                  cols: '6',

                  descriptions: [
                    {
                      id: 1,
                      description: '',
                      label: 'File Description (optional)',
                      rows: '1',
                    },
                  ],
                },

                {
                  id: 4,
                  cols: '2',

                  iconAddDelete: [
                    {
                      id: 1,
                      title: 'add',
                      style: 'cursor: pointer',
                      iconName: 'mdi-plus-circle',
                      click: 'add-row',
                    },

                    {
                      id: 2,
                      class: 'ml-5',
                      title: 'delete',
                      style: 'cursor: pointer',
                      iconName: 'mdi-delete-circle',
                      click: 'delete-row',
                    },
                  ],

                  iconDelete: [
                    {
                      id: 1,
                      title: 'delete',
                      style: 'cursor: pointer',
                      iconName: 'mdi-delete-circle',
                      click: 'delete-row',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];

      const [
        {
          rows: [
            {
              columns: [
                ,
                {
                  selectCategories: [{ items }],
                },
              ],
            },
          ],
        },
      ] = this.uploadSection;

      this.categoryData.forEach((element) => {
        items.push(element.category_name);
      });
    },

    async uploadFile() {
      this.isUploading = true;
      let formData = new FormData();

      for (let rows = 0; rows < this.getUploadRowsLen; rows++) {
        if (
          this.uploadSection[0].rows[rows].columns[1].selectCategories[0]
            .category !== ''
        ) {
          // this will get the files from v-input-file
          let files =
            this.uploadSection[0].rows[rows].columns[0].inputFiles[0].files;

          // this will get the selected category id
          this.categoryData.forEach((element) => {
            if (
              this.uploadSection[0].rows[rows].columns[1].selectCategories[0]
                .category === element.category_name
            ) {
              this.selectedCategoryId = element.id;
            }
          });

          // this will get the description
          let description =
            this.uploadSection[0].rows[rows].columns[2].descriptions[0]
              .description;

          // this will store the upload data in formData
          let i = 1;
          for (const file of files) {
            console.log(file);
            formData.append(`file${i}`, file);
            i++;
          }

          formData.append(`description`, description);
          formData.append(`deal_id`, this.dealData.id);
          formData.append(`category_id`, this.selectedCategoryId);

          console.log('sample: ', formData);

          try {
            const response = await API().post(
              '/api/internal-admin/upload_deal_files',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            console.log(response);
          } catch (error) {
            console.log(error);
          }
        }
        formData = new FormData();
      }
      this.isUploadComplete = true;

      setTimeout(() => {
        this.resetUploadFields();

        this.$emit('refreshDealFiles');
      }, 4000);
    },
  },

  mounted() {
    this.getCategoryData();
  },
};
</script>
