var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.uploadFile.apply(null, arguments)}}},[_c('p',{staticStyle:{"color":"gray"}},[_vm._v(" Notice: The file type must be MSword, MSexcel, pdf or image: jpeg/png. ")]),_vm._l((this.uploadSection),function(uploadSection){return _c('div',{key:uploadSection.id},_vm._l((uploadSection.rows),function(row,index){return _c('v-row',{key:index,class:row.class},_vm._l((row.columns),function(column){return _c('v-col',{key:column.id,staticClass:"overflow-hidden",attrs:{"cols":column.cols}},[_vm._l((column.inputFiles),function(inputFile){return _c('div',{key:inputFile.id},[_c('v-file-input',{attrs:{"multiple":"","accept":inputFile.accept,"label":inputFile.label,"rules":inputFile.rules},model:{value:(inputFile.files),callback:function ($$v) {_vm.$set(inputFile, "files", $$v)},expression:"inputFile.files"}})],1)}),_vm._l((column.selectCategories),function(selectCategory){return _c('div',{key:selectCategory.id},[_c('v-select',{attrs:{"items":selectCategory.items,"label":selectCategory.label,"rules":selectCategory.rules},model:{value:(selectCategory.category),callback:function ($$v) {_vm.$set(selectCategory, "category", $$v)},expression:"selectCategory.category"}})],1)}),_vm._l((column.descriptions),function(description){return _c('div',{key:description.id},[_c('v-textarea',{attrs:{"dark":"","label":description.label,"rows":description.rows},model:{value:(description.description),callback:function ($$v) {_vm.$set(description, "description", $$v)},expression:"description.description"}})],1)}),_c('div',_vm._l((_vm.getUploadRowsLen === index + 1 // this is to define that the add icon should be always at the last row
              ? column.iconAddDelete
              : column.iconDelete),function(icon){return _c('v-icon',{key:icon.id,class:icon.class,style:(icon.style),attrs:{"title":icon.title},on:{"click":function($event){icon.click === 'add-row'
                ? _vm.addUploadRow()
                : _vm.deleteUploadRow(index)}}},[_vm._v(" "+_vm._s(icon.iconName)+" ")])}),1)],2)}),1)}),1)}),_c('div',[_c('v-row',[_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUploadComplete),expression:"!isUploadComplete"}],attrs:{"type":"submit","transition":"fade-transition","disabled":this.uploadSection[0].rows[0].columns[0].inputFiles[0].files ===
            null
              ? true
              : false}},[(!_vm.isUploading)?_c('span',[_vm._v("upload ("+_vm._s(_vm.getUploadRowsLen)+")")]):_vm._e(),(_vm.isUploading && !_vm.isUploadComplete)?_c('span',[_vm._v(" uploading files "),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()])],1)],1),_c('v-row',[_c('v-col',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUploadComplete),expression:"isUploadComplete"}],attrs:{"transition":"fade-transition","type":"success"}},[_vm._v(" Uploaded successfully except for other file types that are not mentioned above. ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }